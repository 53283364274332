import { Box, Flex, Text } from "@chakra-ui/react";
import Styles from "./LandingPage.module.css";
import { useLanguage } from "../../hooks/useLanguage";
import beckenFooter from "../../public/images/beckenFooterLogo.svg";
import Image from "next/image";
import { GeoLocationInput } from "../geoLocationInput/GeoLocationInput";
import { useTranslation } from "next-export-i18n";
import { useEffect } from "react";
const LandingPage: React.FC = () => {
  const { t, query } = useLanguage();

  useEffect(() => {
    if (localStorage) {
      const stringifiedBecknSession = localStorage.getItem("garuda_session");
      if (stringifiedBecknSession) {
        const parsedBecknSession = JSON.parse(stringifiedBecknSession);
        localStorage.clear();
        return localStorage.setItem(
          "garuda_session",
          JSON.stringify(parsedBecknSession)
        );
      }
      localStorage.clear();
    }
  }, []);
  return (
    <Box className={Styles.main_container}>
      <Flex className={Styles.flex_container}>
        <Box className={Styles.heading}>{t("homeHeading")}</Box>
        <Box className={Styles.span_text}>{t("headingSpan")}</Box>
        <Box className={Styles.para_Text}>
          <Text>{t("homeText")}</Text>
        </Box>
        <Box className={Styles.input_group}>
          <GeoLocationInput></GeoLocationInput>
        </Box>
        <Flex className={Styles.footer_container}>
          <Text className={Styles.footerText}>{t("footerText")}</Text>
          <Image
            src={beckenFooter}
            alt="footerLogo"
            width={"39px"}
            height={"13px"}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default LandingPage;
